$color-primary:#1D828F;
$color-primary-hover:#16646E;
$color-primary-press:#10454C;

$color-secondary:#354056;
$color-secondary-hover:#262F44;
$color-secondary-press:#172033;

$color-error:#b6003d;
$color-error-02: #610b26;

$color-red: #de1231;
$color-mint: #14a3cc;
$color-blue: #1D76FB;
$color-yellow: #cc6601;

$color-gray-01: #333333;
$color-gray-02: #555555;
$color-gray-03: #767676;
$color-gray-04: #f5f6fa;
$color-gray-05: #757575;

$color-side-bar : #E8EBF0;
$color-border-01:#949494;
$color-border-02:#cccccc;
$color-border-03:#dcdcdc;
$color-border-04:#848C9D;
$color-border-05:#dadde2;

////////////////////////////////////////////////////////////

$color-content-bg:#f2f2f2;
$color-content-bg-1:#f5f5f5;
$color-content-bg-2:#e9e9e9;
$color-content-bg-3:#f8f8f9;

$color-black:#000;
$color-white:#fff;

$color-dark-gray:$color-gray-01;
$color-gray:$color-gray-02;
$color-light-gray:$color-gray-03;

$color-border:$color-border-02;
$color-disabled:#757575;
$color-hover:#e4e4e4;

$color-dashboard-red: #d70d0d;
$color-dashboard-mint: #14a1a9;
$color-dashboard-yellow: #f79a08;
$color-dashboard-gray: #8a8a8a;

$color-filter-gray: #262F44;
