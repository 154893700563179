@charset 'UTF-8';

// 1. Configuration and helpers
@import
  'abstracts/variables',
  'abstracts/functions',
  'abstracts/mixins';

// Dropdown //
.field {
  .input-box {
    .dropdown-menu {
      z-index:32;
      overflow:hidden;
      position:absolute;
      top:4.3rem;
      left:0;
      min-width:100%;
      border-radius: 1.1rem;
      border:1px solid #d0d1d2;
      background-color:$color-white;
      box-shadow: 0px 6px 12px 0px rgba(0, 0, 0, 0.2);
      backdrop-filter: blur(0px);
      .lists {
        .list {
          a {
            display:block;
            padding:1.2rem;
            @include set-font(1.4rem, 400, $color-dark-gray, 1.4);
            padding-right: 4rem;
            
            &:hover {
              background-color:$color-gray-04;
              text-decoration:underline;
            }
            &:active {
              background-color:#dadde2;
              text-decoration:none;
              border: 2px solid $color-primary;
            }
          }
          &:first-of-type {
            a {
              border-radius: 0.6rem 0.6rem 0 0;
            }
          }
          &:last-of-type {
            a {
              border-radius: 0 0 0.6rem 0.6rem;
            }
          }
        }
      }
    } // .dropdown-menu
  }
}

.field-form {
  &.flex {
    .dropdown {
      margin-bottom:0;
      margin-left:1.5rem;
  
      &:first-child {
        margin-left:0;
      }
    }
  }
}

.dropdown-label {
  display: block;
  padding-bottom: 0.4rem;
  @include set-font(1.2rem, 400, $color-gray-02, 1.4);
}

.dropdown {
  position:relative;
  // position: static;
  &.disabled {
    opacity: 0.4;
  }
  .btn-dropdown {
    position:relative;
    z-index:21;
    display:block;
    padding: 0.7rem 3.6rem 0.7rem 1.2rem;
    min-width:100%;
    border: 1px solid $color-border-05;
    border-radius: 0.6rem;
    background-color: #fff;
    @include set-font(1.4rem, 400, $color-gray-01, normal);
    text-align:left;
    cursor: pointer;
    backdrop-filter: blur(0px);
    &:after {
      content:'';
      position:absolute;
      right: 1rem;
      top:50%; 
      display:inline-block;
      margin-top: -0.8rem;
      width: 1.6rem;
      height: 1.6rem;
      background:asset-image('ic_arrow_down.svg') no-repeat center / 100%;
    }

    &:hover {
      background-color: $color-gray-04;
      text-decoration:underline;
    }

    &[aria-expanded=true] {
      // border-bottom-color:$color-primary;
      &:after {
        background:asset-image('ic_arrow_up.svg') no-repeat center / 100%;
      }
      + .dropdown-menu {
        display:block;
        top:calc(100% + 0.4rem);
        left:inherit;
      }
    }

    &:disabled {
      color:rgba($color-gray-01, 0.5);
      border-bottom-color:#d0d1d2;
      &:hover {
        text-decoration:none;
      }
      &:after {
        opacity:0.5;
      }
    }
  }

  &.dropdown-type-b {
    width:12rem;
    .btn-dropdown {
      @include set-flex(row, flex-start, center);
      padding:0.7rem 2.3rem 0.7rem 1.2rem;
      border:0;
      &:before {
        content:'';
        display:inline-block;
        margin-right:0.6rem;
      }
      &:after {
        width:1.1rem;
        height:0.6rem;
        background:asset-image('header_btn_drop_down.svg') no-repeat center / 1rem 0.6rem;
      }

      &[aria-selected=true] {
        border-radius:1.7rem;
        background-color:#f4f4f4;
        &:after {
          background:asset-image('header_btn_drop_up.svg') no-repeat center / 1rem 0.6rem;
        }
      }
    }
  }

  &.dropdown-overflow {
    .btn-dropdown {
      height: 3.6rem;

      div {
        height: 100%;
        overflow: hidden;
      }
    }
  }

  &.up {
    .dropdown-menu {
      top: auto !important;
      bottom: 4.3rem;
    }
  }

  .dropdown-menu {
    display:none;
    overflow:hidden;
    position:absolute;
    top:4.3rem;
    left:0;
    z-index:32;
    min-width: 100%;
    border-radius: 0.6rem;
    border:1px solid #dadde2;
    background-color:$color-white;
    box-shadow: 0px 6px 12px 0px rgba(0, 0, 0, 0.2);

    .lists {
      margin-right: 0.8rem;
      overflow:hidden;
      overflow-y: auto;
      max-height:19.5rem;

      &::-webkit-scrollbar {
        background-color: transparent !important;
      }

      .list {
        // position: relative;
        a {
          display:block;
          padding:1.2rem;
          @include set-font(1.4rem, 400, $color-dark-gray, 1.4);
          // white-space: nowrap;
          padding-right: 4rem;

          &:hover {
            background-color:$color-gray-04;
            text-decoration:underline;
          }
          &:active {
            background-color:#dadde2;
            text-decoration:none;
            font-weight: 700;
          }
        }
        &:first-of-type {
          a {
            border-radius: 0.6rem 0.6rem 0 0;
          }
        }
        &:last-of-type {
          a {
            border-radius: 0 0 0.6rem 0.6rem;
          }
        }
      }
    }
    &.border-type {
      .lists {
        .list {
          border-bottom: 1px solid #e5e5e5;
          &:last-child {
            border-bottom: none;
          }
        }
      }
    }
    &.list-delete {
      .lists {
        .list {
          position: relative;
          .btn-input-remove { 
            overflow: hidden;
            text-indent: -9999px;
            font-size: 0;
          }
        }
      }
    }
  } // .dropdown-menu
}

@media screen and (max-width:640px) {
  // Dropdown //
  .field {
    .field-label {

    }

    .field-form {
      .dropdown {
        .btn-dropdown {
          font-size:1.5rem;
        }
      }
    }

    .dropdown-menu {
      .lists {
        .list {
          a {
            font-size:1.5rem;
          }
        }
      }
    }
  }
}
