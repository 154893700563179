@charset 'UTF-8';

// 1. Configuration and helpers
@import
  'abstracts/variables',
  'abstracts/functions',
  'abstracts/mixins';

.tooltip-container {
  .tooltip-label {
    position:relative;
    // &:hover {
    //   .tooltip {
    //     display:block;
    //   }
    // }

    // &:focus {
    //   .tooltip {
    //     display:block;
    //   }
    // }
    &.hover {
      .tooltip {
        display:block;
      }
    }
  }
}

.tooltip {
  z-index: 100;
  position:absolute;
  display:none;
  width:13rem;
  border-radius:0.4rem;
  background-color:#313238;
  &.top {
    bottom:calc(100% + 1rem);
    left:50%;
    transform:translate(-50%,0);
    .tooltip-body {
      &:before {
        left:50%;
        bottom:-1.2rem;
        transform:translate(-50%,0);
        border-color:#313238 transparent transparent transparent;
      }
    }
  }
  &.bottom {
    top:calc(100% + 1rem);
    left:50%;
    transform:translate(-50%,0);
    .tooltip-body {
      &:before {
        left:50%;
        top:-1.2rem;
        transform:translate(-50%,0);
        border-color:transparent transparent #313238 transparent;
      }
    }
  }
  &.left {
    top:50%;
    right:calc(100% + 1rem);
    transform:translate(0, -50%);
    .tooltip-body {
      &:before {
        top:50%;
        right:-1.2rem;
        transform:translate(0,-50%);
        border-color:transparent transparent transparent #313238;
      }
    }    
  }
  &.right {
    top:50%;
    left:calc(100% + 1rem);
    transform:translate(0, -50%);
    .tooltip-body {
      &:before {
        top:50%;
        left:-1.2rem;
        transform:translate(0,-50%);
        border-color:transparent #313238 transparent transparent;
      }
    }
  }
  .tooltip-body {
    position:relative;
    padding:0.6rem 1.2rem;
    @include set-font(1.3rem, 400, $color-white, normal);
    &:before {
      content:'';
      position:absolute;
      display:block;
      border-style:solid;
      border-width:6px;
    }
  }
  &.info {
    width:23rem;
    top: calc(100% + 1.5rem);
  }


}