@import './variables';
@import './functions';

@mixin test_textReflow($font-size) {
  line-height: $font-size * 1.5;
  margin-top: $font-size * 2;
  margin-bottom: $font-size * 2;
  letter-spacing: $font-size * 0.12;
  word-spacing: $font-size * 0.16;
}

/* Text */
/**
    한 줄(싱글 라인) 텍스트의 생략을 적용한다.
    텍스트의 길이(너비)가 일정 이상이 되었을 때, 생략 및 말줄임표 스타일을 적용한다. 또한 반대로 해제할 수 있다.
    요소에 width 혹은 max-width가 설정되어야 한다.

    @param $toggle-bool - 텍스트 생략 여부
*/
@mixin set-text-hidden($toggle-bool) {
  @if($toggle-bool) {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  @else {
    white-space: normal;
    text-overflow: clip;
    overflow: visible;
  }
}

/**
    여러 줄(멀티 라인) 텍스트의 생략을 적용한다.
    텍스트의 길이(너비)가 일정 이상이 되었을 때, 생략 및 말줄임표 스타일을 적용한다. 또한 반대로 해제할 수 있다.
    요소에 width 혹은 max-width로 너비를 설정하고 height 혹은 max-height로 높이를 설정해야한다. 
    또한 line-height로 텍스트의 노출 정도를 조절해야 한다.

    @param $toggle-bool - 텍스트 생략 여부
    @param $line-number - 노출할 텍스트의 줄 수
*/
@mixin set-multi-text-hidden($toggle-bool, $line-number) {
  @if($toggle-bool) {
    display: -webkit-box;
    overflow: hidden;
    -webkit-line-clamp: $line-number;
    -webkit-box-orient: vertical;
  }

  @else {
    display: inherit;
    overflow: visible;
    -webkit-line-clamp: none;
    -webkit-box-orient: unset;
  }
}

@mixin set-font($size, $weight, $color, $height:normal) {
  font-size: $size;
  font-weight: $weight;
  color: $color;
  line-height: $height;
}

@mixin ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

@mixin set-flex($direction:row, $justify:flex-start, $align:flex-start) {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;

  // Set flex //
  @if $direction==row {
    -webkit-box-orient: horizontal;
    -ms-flex-direction: row;
    flex-direction: row;
  }

  @else if $direction==row-reverse {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: reverse;
    -ms-flex-direction: row-reverse;
    flex-direction: row-reverse;
  }

  @else if $direction==column {
    -webkit-box-orient: vertical;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  @else if $direction==column-reverse {
    -webkit-box-orient: vertical;
    -webkit-box-direction: reverse;
    -ms-flex-direction: column-reverse;
    flex-direction: column-reverse;
  }

  // set justify-content //
  @if $justify==flex-start {
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
  }

  @else if $justify==flex-end {
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
  }

  @else if $justify==center {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
  }

  @else if $justify==space-between {
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }

  // set align-items //
  @if $align==flex-start {
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
  }

  @else if $align==flex-end {
    -webkit-box-align: end;
    -ms-flex-align: end;
    align-items: flex-end;
  }

  @else if $align==center {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }

  @else if $align==stretch {
    -webkit-box-align: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
  }

  @else if $align==baseline {
    -webkit-box-align: baseline;
    -ms-flex-align: baseline;
    align-items: baseline;
  }
}

@mixin set-flex-grow($grow) {
  -webkit-box-flex: $grow;
  -ms-flex: $grow;
  flex-grow: $grow;
}

// normal focus effect
@mixin normal-focus {
	outline:.2rem solid $color-indigo-06;
}

