@charset 'UTF-8';

// 1. Configuration and helpers
@import
  'abstracts/variables',
  'abstracts/functions',
  'abstracts/mixins';

  // Date Picker //

.field-datepicker {
  margin-bottom:1.5rem;

  .separator {
    position:relative;
    // bottom:-0.8rem;
    margin:0 1rem;
  }

  .datepicker-container {  
    .input-box {
      @include set-flex(row, flex-start, center);
      border:1px solid $color-border-05 !important;
      border-radius: .6rem;
      border:1px solid;
      .datepicker {
        width: 10rem;
        // border-bottom: 0;
        border: 0;
        font-size: 1.4rem;
      }
    }
  }
}

.ui-datepicker {
  overflow:hidden;
  width:calc(100% - 8rem);
  max-width: 40rem;
  border-radius: .8rem;
  background-color: $color-white;
  box-shadow: 0px 2px 6px 0 rgba(0, 0, 0, 0.25);
  z-index: 999 !important;
  table {
    margin: 2rem;
    width: auto;
    height: 100%;
    tbody {
      position: static;
      // margin-top: 2rem;
      height:auto;
      border:0;
      overflow-y: visible;
    }
  }
}

.ui-datepicker-header {
  @include set-flex(row, flex-end, center);
  position:relative;
  padding:2.8rem 2.4rem .8rem;
  // border-bottom:1px solid $color-border-03;

  .ui-datepicker-prev {
    overflow:hidden;
    position:absolute;
    right:6rem;
    width:14px;
    height:22px;
    background: asset-image('calendar_prev.svg') center no-repeat;
    text-indent: -9999px;
  }

  .ui-datepicker-next {
    overflow:hidden;
    position:absolute;
    right:2rem;
    width:14px;
    height:22px;
    background: asset-image('calendar_next.svg') center no-repeat;
    text-indent: -9999px;
  }

  .ui-datepicker-title {
    width:100%;
    padding-right:8.4rem;
    text-align:left;

    
    .ui-datepicker-month {
      width: auto;
      @include set-font(1.8rem, bold, $color-gray-01);
      background-color: $color-white;
      border: 0;
      // &:focus {
      //   outline: 0;
      // }
    }

    .ui-datepicker-year {
      width: auto;
      @include set-font(1.8rem, bold, $color-gray-03);
      background-color: $color-white;
      border: 0;
      // &:focus {
      //   outline: 0;
      // }
    }
  }
 
}

.ui-datepicker-calendar {
  thead {
    border-bottom:none !important;

    th {
      @include set-font(1.4rem, 700, $color-gray-01);
      border-right:none !important;
      background-color:$color-white;

      // &.ui-datepicker-week-end {
      //   color:#de1231;

      //   &:last-child {
      //     color:#0fa3a3;
      //   }
      // }
    }
  }

  tbody {
    tr {
      border-bottom: 0 !important;
      background-color: transparent !important;

      td {
        padding:0.7rem 0.6rem !important;
        border-right: none !important;

        a {
          display: inline-block;
          width:4rem;
          height: 4rem;
          border-radius: 50%;
          text-align:center;
          line-height: 4rem;
        }

        &:hover {
          a {
            background-color: #E8EBF0;
            // color: $color-white;
          }
        }
      }
    }
  }

}

.ui-datepicker-buttonpane {
  @include set-flex(row, center, center);
  padding:2rem;
  // border-top:1px solid $color-border-03;

  button {
    @include set-font(1.4rem, 700, $color-gray-01);
    width:80%;
    max-width:14rem;
    height:4.2rem;
    border-radius:.8rem;
    border:1px solid $color-border-05;
    & + button {
      margin-left: 1rem;
    }
  }
}

.ui-datepicker-current-day {
  a {
    color: $color-white;
    background-color: $color-secondary;
  }
}

button.ui-datepicker-trigger {
  @include set-flex(row, center, center);
  width:3rem;
  height:3rem;
  // margin:0 0 0 1rem;
  // border:1px solid $color-border-01;
  // border-radius: 5rem;
  // background:asset-image('btn_icon_calendar.svg') center no-repeat;
  // img {
  //   display:none;
  // }
}

// 팝업 내 팝업창으로 영역 손실 대응
// @media screen and (max-width:1280px) {
//   .ui-datepicker {
//     overflow-y: auto;
//     top: 2rem !important;
//     left: 0 !important;
//     right: 0 !important;
//     bottom: 2rem !important;
//     max-width: none;
//     height: calc(100% - 4rem);
//     margin: 0 auto;
//     border-radius: 0;
//     table {
//       overflow-y: auto;
//     }
//   }
// }