@charset 'UTF-8';

// 1. Configuration and helpers
@import
  '../abstracts/variables',
  '../abstracts/functions',
  '../abstracts/mixins';

  // Switch //

.switch {
  position:relative;
  display:inline-block;
  min-width:4.6rem;
  height:2.7rem;

  &.show-label {
    label {
      text-indent: 0;
      opacity: 1;
    }
  }

  label {
    display: block;
    overflow:hidden;
    position:absolute;
    top:0;
    left:0;
    bottom:0;
    z-index: 2;
    width:100%;
    text-indent: -9999px;
    opacity: 0;
    cursor: pointer;
  }

  input {
    opacity:0;
    width:0;
    height:0;
    &:disabled {
      + .slider {
        opacity:0.4;
        cursor:default;
      }
    }

    &:focus + .slider {
      outline: 2px solid $color-black;
    }
  }

  /* The slider */
  .slider {
    position:absolute;
    cursor:pointer;
    top:0;
    left:0;
    right:0;
    bottom:0;
    background-color:#757575;
    -webkit-transition:0.2s;
    transition:0.2s;
    border-radius:1.35rem;

    &:before {
      content:"";
      position:absolute;
      top:50%;
      left:0.4rem;
      width:1.9rem;  
      height:1.9rem; 
      margin-top:-0.95rem;
      background-color:white;
      -webkit-transition:0.2s;
      transition:0.2s;
      border-radius:50%;
    }
    &:after {
      // content: 'Off';
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      padding: 0 6px;
      @include set-font(1.1rem, 300, $color-white, 2.8rem);
      box-sizing: border-box;
      text-align: right;
    }
  }
}

input:checked + .slider {
  background-color:$color-primary;
  &:after {
    // content: 'On';
    content: '';
    text-align: left;
  }
}

input:checked + .slider:before {
  left:calc(100% - 2.3rem);
}