@charset 'UTF-8';

// 1. Configuration and helpers
@import
  'abstracts/variables',
  'abstracts/functions',
  'abstracts/mixins';

// Radio//
.field-type-radio {
  .field-form {
    .field-box {
      margin:0 -0.65rem;
      .radio {
        margin:0 0.65rem 1rem;
        .custom {
          display: inline-block;
          margin-bottom: 0rem;
          margin-left: 1rem;
          input[type="text"] {
            padding: 0.8rem 1rem;
          }
        }
      }
    }
  }
}

.field-radio {
  position: relative;

  &.no-label {
    display: inline-block;

    input[type="radio"] {
      & + label {
        width: 1.8rem;
        height: 2.4rem;
      }
    }
  }
}

.field-form {
  &.flex {
    .radio {
      margin-bottom:0;
      margin-left:1.5rem;
  
      &:first-child {
        margin-left:0;
      }
    }
  }
}

input[type="radio"] {
  position: absolute;
  width: 0;
  height: 0;

  + label {
    @include set-font(1.6rem, 400, $color-dark-gray, normal);
    display: inline-block;
    position: relative;
    padding-left: 3rem;
    cursor: pointer;

    &:before {
      content: "";
      position: absolute;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
      width: 1.9rem;
      height: 1.9rem;
      margin-top:1px;
      border-radius: 50%;
      border:2px solid $color-border-01;
      transition: border-color 0.25s;
      box-sizing: border-box;
    }

    &:after {
      content: "";
      position: absolute;
      top: 50%;
      left: .5rem;
      transform: translateY(-50%);
      width: .9rem;
      height: .9rem;
      margin-top:.1rem;
      border-radius: 50%;
      transition: border-color 0.25s;
      box-sizing: border-box;
    }
  }

  ~ .field-description {
    @include set-font(1.4rem, 400, $color-gray-03);
    padding-left:3rem;
  }

  &:disabled {
    + label {
      cursor: default;
      opacity: 0.4;
    }
  }

  &:focus-visible {
    + label {
      &:before {
        outline:2px solid $color-black;
      }
    }
  }

  &:checked {
    + label {
      &:before {
        content:'';
        width:1.9rem;
        height:1.9rem;
        border:none;
        background-color:$color-primary;
      }

      &:after {
        content:'';
        width:.9rem;
        height:.9rem;
        border:none;
        background-color:$color-white;
      }
    }
    &:disabled{
      + label{
        opacity: 0.4;
      }
    }
    &.intermediate {
      + label {
        &:before {
          border-color: $color-primary;
        }
      }
    }
  }
}
