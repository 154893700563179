@charset 'UTF-8';

// 1. Configuration and helpers
@import
  'abstracts/variables',
  'abstracts/functions',
  'abstracts/mixins';


  select {
    width: 100%;
    height: 3.6rem;
    padding: 0.7rem 3rem 0.7rem 1rem;
    // border:0;
    // border-bottom: 1px $color-border-01 solid;
    border: 1px solid $color-border-05;
    border-radius: 0.6rem;
    @include set-font(1.4rem, 400, $color-gray-01, normal);
    background: #fff asset-image('ic_arrow_down.svg') no-repeat 95%;
    appearance: none;
    cursor: pointer;
    // arrow hide
    &::-ms-expand {
        display: none;
    }
    option {
      @include set-font(1.4rem, 400, $color-dark-gray, normal);
    }
    
  }

  .show-items {
    text-align: right;
  
    label {
      @include set-font(1.35rem, 400, $color-gray-01, 3.1rem);
      margin-right: 1rem;
    }
  
    select {
      width: auto;
      height:3.4rem;
      padding: 0 4rem 0 2rem;
      border-radius:1.7rem;
      border: none;
      background:#f4f4f4 asset-image('navi_btn_drop_down_3.svg') no-repeat 80% center / 1rem 0.6rem;
    }
  }

  table {
    thead {
      th {
        select {
          height: 3.6rem;
          padding: .8rem 1rem;
          border: 0;
        }
      }
    }
  }