@charset 'UTF-8';

// 1. Configuration and helpers
@import 'abstracts/variables',
'abstracts/functions',
'abstracts/mixins';

@import 'element/text',
'element/textarea',
'element/radio',
'element/checkbox',
'element/switch',
'element/button',
'element/search',
'element/select',
'element/dropdown',
'element/tooltip',
'element/datepicker';

fieldset {
  min-width: 0;
}

// field //
.form-guide {
  @include set-font(1.4rem, 400, $color-error, normal);
  text-align: right;
}

// Field 공통 레이아웃 //

.field {
  margin-bottom: 1.6rem;
  font-size: 1.4rem;
  // letter-spacing: -0.56px;

  &.field-flex {
    @include set-flex(row, flex-start, flex-start);
    flex-wrap: wrap;
    
    &.field-type-switch {
      align-items: center;
    }
    
    .field-label {
      @include set-flex(row, flex-end, center);
      @include set-font(1.6rem, 500, $color-gray-01);
      height: 3.2rem;
      margin: 0 1rem 0 0;
      // text-align: right;
    }

    .field-form {
      // @include set-flex-grow(1);
      // margin-right:1.5rem;

      .btn-form {
        @include set-flex(row, flex-start, center);

        .switch {
          margin-right: 1.3rem;
        }
      }
    }

    .input-group {
      @include set-flex(row, flex-start, center);

      .input-box {
        @include set-flex-grow(1);
      }

      >.btn {
        margin-left: 1rem;
      }

      .unit {
        @include set-font(1.4rem, 400, $color-gray-01);
        display: inline-block;
        margin-left: 1rem;
      }
    }

  }

  &.required {
    .field-label {
      &:after {
        @include set-font(1.6rem, 500, $color-blue, normal);
        content: '*';
        margin-left: .2rem;
      }
    }
  }

  .input-box {
    position: relative;
  }

  .field-label {
    @include set-font(1.6rem, 500, $color-gray-01, normal);
    display: inline-block;
    margin-bottom: .5rem;
  }

  .field-content {
    @include set-font(1.4rem, normal, $color-black, 2rem);
  }

  .field-description {
    @include set-font(1.2rem, normal, $color-gray-05, 1.6rem);
    display: block;
    margin-top: 0.8rem;
    padding: 0 1.2rem;
    word-break: keep-all;

    &.error {
      color: $color-error;
    }
  }

  &.field-checkbox {
    @include set-font(1.4rem, normal, $color-dark-gray, 1.6rem);

    label {
      line-height: inherit;
    }
  }

  //flex type
  .flex-between {
    @include set-flex(row, space-between, center);
    margin-bottom: 0.8rem;
  }

  &.error {

    input[type="text"],
    input[type="search"],
    input[type="password"],
    input[type="email"],
    input[type="tel"],
    input[type="number"],
    .select-box {
      border: 1px solid $color-primary !important;

      &.underline-input {
        border: none;
        border-bottom: 1px solid $color-primary;
      }
    }
  }
}

.field-form {
  position: relative;

  .field-box {
    @include set-flex(row, flex-start, center);
    flex-wrap: wrap;
    margin: 0 -0.6rem;

    >* {
      flex: 1;
      margin: 0 0.6rem;

      &.btn,
      &.unit,
      &.checkbox,
      &.radio,
      &.switch,
      &.colon{
        flex: none;
        max-width: 100%;
      }
    }
    > textarea {
      margin: 0;
    }

    &.not-flex {
      display: block;
    }

    .checkbox,
    .radio {
      margin-bottom: 1rem;
    }
  }

  &.flex {
    @include set-flex(row, flex-start, center);
    align-self: stretch;
  }

  &.middle {
    width: 30rem;
  }

  .form-title {
    display: block;
    padding: 1rem 1.6rem;
    margin-top: 2rem;
    margin-bottom: 1rem;
    @include set-font(1.6rem, 500, $color-gray-02, normal);
    background-color: #f8f8f9;
    &:first-of-type {
      margin-top: 0;
    }
  }
}

// Input 관련
input.input-txt {
  @include set-font(1.4rem, 400, #757575, normal);
  border: 1px solid $color-border-05;
  border-radius: 0.6rem;
}

// Board
.tab-panel {
  table {
    margin-bottom: 1.7rem;
    // min-width: 100%;
    // overflow-x: auto;
  }
}


// slide bar
.field-slide-bar {
  width:100%;
  .field-form {
    @include set-flex(row, space-between, center);

    .field-value {
      margin-right:2rem;
      input {
        width:8rem !important;
        height:3rem;
        border:1px solid $color-border-03;
      }
    }

    .slide-bar-container {
      @include set-flex(row, space-between, center);
      @include set-flex-grow(1);

      .btn {
        display: block;
        min-width: 2.4rem !important;
        width: 2.4rem !important;
        height: 2.4rem;
        padding:0;
        border-radius:1.2rem;
        border:1px solid $color-border-01;
        text-indent: -9999px;

        &.btn-minus {
          background: asset-image('minus_icon_details_n.svg') center no-repeat;
        }

        &.btn-plus {
          background: asset-image('plus_icon_details_n.svg') center no-repeat;
        }
      }

      input[type=range] {
        appearance: none;
        -webkit-appearance: none;
        width:calc(100% - 7.2rem);
        height:.6rem;
        margin:0 1.2rem;
        border-radius:.3rem;
        border:none;
        background-color: #f1f1f1;
        cursor: pointer;

        &::-webkit-slider-thumb {
          -webkit-appearance: none;
          width:1.4rem;
          height:1.4rem;
          border-radius:50%;
          background-color: $color-primary;
        }
      }
    } // .slide-bar-container
  }
}


//200%
@media screen and (max-width:991px) {
  .field {

    &.field-slide-bar {
      &.field-set-value {
        .field-form {
          display: block;

          .slide-bar-container {
            margin-bottom: 1rem;
          }
        }
      }
    }

    &.field-flex {
      &.field-type-switch {
        .field-label {
          margin-right: 2rem !important;
          width: auto !important;
          height: auto !important;
        }
      }
      .field-label {
        height: 3.4rem !important;
        margin-right: 2rem !important;
        margin-bottom: 1rem;
      }
      .field-form {
        + .btn {
          margin-top: 1rem;
        }
      }
    }
    &.field-type-switch {
      &.double-line-label {
        .field-form {
          margin-top: .5rem;
          width: 100%;
          .field-box {
            .switch {
              + .btn {
                margin-top:0;
              }
            }
          }
        }
      }
      .field-form {
        width: auto;
        .btn {
          margin-top: 0;
        }
      }
    }
    &.double-line-label {
      height: auto !important;
      .field-form {
        .field-box {
          @include set-flex(row, flex-start, center);
          .switch {
            + .btn {
              flex: 1;
            }
          }
        }
      }
    }
    .field-label {
      height: auto !important;
      margin-right: 0 !important;
      margin-bottom: 0.8rem;
      padding: 0 !important;
    }

    .field-form {
      margin-left: 0 !important;
      margin-right: 0 !important;

      .field-box {
        .dropdown {
          width: 100% !important;
          margin: 0;

          + .dropdown {
            margin: 1.6rem 0 0;
          }

          + input.input-txt {
            margin: 1.6rem 0 0;
          }
        }

      }

      .input-box {
        margin: 0;
        width: 100%;
        height: auto;
      }

      // input[type="text"],
      // input[type="password"],
      // input[type="email"],
      // input[type="tel"],
      // input[type="number"] {
      //   &:read-only {
      //     padding-left: 0;
      //   }
      // }
    }
    
    .field-form {
      &.field-form-type {
        .field-box {
          @include set-flex(column, flex-start, flex-start);
        }
      }
    }

  }

  input.input-txt {
    width: 100% !important;
  }
}

@media screen and (max-width:640px) {
  .field {
    &.field-flex {
      &.field-type-switch {
        .field-label {
          margin-bottom: 0;
        }
      }
      .field-label {
        justify-content: flex-start;
      }
    }
    .field-label {
      @include set-font(1.7rem, 500, $color-gray-01, normal);
    }

    .field-form {
  
      .field-box {
        @include set-flex(column, flex-start, flex-start);
        margin: 0;
        >* {
          &.btn {
            width: calc(100% - 1.2rem);
          }
          &.colon {
            margin-top: 1rem;
            + .dropdown {
              margin: 1.6rem 0 0;
            }
          }
        }
        >.switch {
          margin: 0;
        }
      }

      > .field-box {
        > .table {
          width: 100%;
          margin: 0;
        }
      }
    }

    .flex-between {
      flex-direction: column;
      align-items: flex-start;
    }
    
  }

  .btn {
    padding: 0 1rem;
  }

  .button-box {
    .btn {
      width: 100%;
    }
  }

  .field-form.middle {
    width: 100%;
  }

}